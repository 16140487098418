////////////////////////////////////////////
//			Global Styles
////////////////////////////////////////////
html {
    &.no-scroll {
      body {
        overflow: hidden !important;
      }
    }
  }
  
  [data-popup-trigger] {
    cursor: pointer !important;
  }
  
  .pageWrap {
    transition: filter .25s ease-out;
  
    &.is-blurred {
      filter: blur(3px);
    }
  }
  
  ////////////////////////////////////////////
  //			Specific Popup Instances
  ////////////////////////////////////////////
  #popup_yolanda-video,
  #popup_karl-video {
      position: relative;
      // display: none;
      width: 100%;
      .vjs-dock-text,
      .vjs-dock-shelf {
          display: none;
      }
  }
  
  ////////////////////////////////////////////
  //			General Popup Styles
  ////////////////////////////////////////////
  [data-popup-trigger] {
    cursor: pointer;
  }
  
  .popup {
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    transform: translateZ(2px);
    -webkit-transform: translate3d(0, 0, 0);
  
    &-data {
      display: none;
    }
  
    &-overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: rgba($black, .75);
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 40px 0;
    }
  
    &-content {
      margin-bottom: 50px;
      position: relative;
      z-index: 2;
      display: flex;
      overflow: hidden;
      width: 100%;
      height: auto;
      background: $white;
      background-color: palette(gray, 200);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      box-shadow: 0 0 10px rgba($black, .5);
          margin: auto 0;
          @include media-breakpoint-up(md) {
              width: 90%;
          }
          @include media-breakpoint-up(lg) {
              width: 80%;
          }
    }
  
    &-close {
      position: absolute;
      z-index: 10;
      top: $spacer;
      right: $spacer;
      height: 50px;
          width: 50px;
          padding: $spacer;
          border: 4px solid $white;
          border-radius: 50%;
      cursor: pointer;
  
      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 4px;
        width: 70%;
              background: $white;
              border-radius: 99px;
        transform: rotate(45deg);
      }
  
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  
  [id^="popup_"] {
    display: none;
  }
  
  .popup-content>[id^="popup_"] {
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
  
    >aside {
      position: absolute;
      top: -20px;
      right: 30px;
      text-transform: uppercase;
      font-size: .8rem;
      color: palette(gray, 800);
  
      a {
        margin-left: 5px;
        text-decoration: underline;
        color: palette(gray, 800);
      }
    }
  
    h5 {
      color: palette(gray, 600);
  
      &+.form {
        margin-top: 15px;
      }
    }
  
    p {
      margin-bottom: 10px;
      color: palette(gray, 600);
  
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  
    .button-block {
      display: flex;
    }
  
    .button {
      min-width: 100px;
      text-align: center;
      color: palette(gray, 600);
  
      &:before {
        color: palette(gray, 600);
      }
  
      &:hover,
      &:after {
        color: $white;
      }
    }
  }
  