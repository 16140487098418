.violator {
    background: rgb(47,116,150);
    background: linear-gradient(0deg, rgba(47,116,150,1) 72%, rgba(33,80,110,1) 100%);
    color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    font-family: $font-family-alt;

    font-size: 1.25rem;
    a {
        color: $white;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 6px;
            display: inline-block;
            margin-left: 10px;
            transform: rotate(-45deg);
            top: 1px;
            position: relative;
        }
        &:hover {
            text-decoration: none;
        }
    }
}