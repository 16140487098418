.animation-container {
    position: relative;
}

.animateThis {
    transition: all 1s ease;
}

.up-from-bottom {
    position: relative;
    transform: translateY(2rem);
    transition: all 1s ease;
}
.in-from-left {
    position: relative;
    transform: translateX(-2rem);
    transition: all 1s ease;
}
.in-from-right {
    position: relative;
    transform: translateX(2rem);
    transition: all 1s ease;
}

.animation-item {

}

.animation-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(__("/img/content-images/paper_texture_pattern.jpg"));
    z-index: 2;
    transition: all 1.25s ease;
    text-align: right;
    right: 0;
}

.arrowChart {
    display: inline-block;
    overflow: hidden;
    .arrowChart-img-1 {
        position: relative;
        transform: translateX(-80%);
        transition: all 1s ease;
    }
    .arrowChart-img-2 {
        position: relative;
        transform: translateX(-100%);
        transition: all 1s ease;
        transition-delay: 2s;
        visibility: hidden;
    }
}

//all animate now action goes
.animateNow {
    .up-from-bottom {
        transform: translateY(0);
    }
    .in-from-left {
        transform: translateX(0);
    }
    .in-from-right {
        transform: translateX(0);
    }
    .animation-mask {
        width: 0;
    }
    .arrowChart {
        .arrowChart-img-1 {
            transform: translateX(0);
        }
        .arrowChart-img-2 {
            transform: translateX(-3rem);
            visibility: visible;
        }
    }
}

.outside-image {
    
    @include media-breakpoint-down(sm){
        max-width: 105%;
        width: 105%;
        margin-left: -5%;
        padding-bottom: 8px;
    }
}