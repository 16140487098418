// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//loop through each color in the $palettes map in style/scss/_variables.scss and add the modifiers
@each $palette in $palettes {
    //Grab/Define the main colors e.g. "colorBrandBlue"
    $baseColorName: nth($palette, 1);
    //Grab/Define the modifiers, e.g. "lighter"
    $modifiers: nth($palette, 2);
//Generate helper classes
@each $name, $value in $modifiers {
  //when we find the name "base" we want to remove it from the helper classes
  // i.e. good = ".colorBrandBlue", bad = ".colorBrandBlueBase"
    @if $name == base {$name : '';}
    //Generate text color classes
    .#{$baseColorName}#{capitalize($name)} {
      color: $value !important;
    }
    //Generate text color classes
    .fill#{capitalize($baseColorName)}#{capitalize($name)} {
      background-color: $value !important;
      &, a:not(.btn), a:not(.btn):hover {
          color: color-contrast($value);
      }
    }
  }
}

.bg-tan {
  background: url(__("/img/content-images/paper_texture_pattern.jpg"))
}
.bg-light-tan {
  background-color: $colorBrandLightTan !important;
}

.bg-orange {
  background-color: $colorBrandOrange;
  background: url(__("/img/template-images/paper_texture_pattern_orange.jpg"));
}

.bg-aqua {
  background-color: $colorBrandAqua;
  background: url(__("/img/template-images/paper_texture_pattern_blue.jpg"))
}




.contain-more {
  @include media-breakpoint-up(lg) {
    max-width: 1080px;
  }
}
.contain-less {
  max-width: 1425px;
  padding-left: 0;
  padding-right: 0;
}
.contain-522 {
  max-width: 522px;
}
.color-aqua {
  color: $colorBrandAqua;
}
.color-aqua2 {
  color: $colorBrandAqua2;
}

.color-orange {
  color: $colorBrandOrange;
}
.color-purple {
  color: $colorBrandPurple !important;
}
.border-purple {
  border: 2px solid $colorBrandPurple;
}
.color-red {
  color: $colorBrandRed;
}
.color-red2 {
  color: $colorBrandRed2;
}
.color-white {
  color: $white !important;
}
.border-bullet {
  position: relative;
  &:before {
    content: '';
    width: 2px;
    height: 18px;
    background-color: $colorBrandOrange;
    position: absolute;
    top: 8px;
    left: -10px;
    @include media-breakpoint-up(md){
      width: 3px;
      height: 22px;
      top: 8px;
      left: -14px;
    }
  }
}
h3.border-bullet {
  position: relative;
  &:before {
    content: '';
    height: 14px;
    background-color: $colorBrandOrange;
    position: absolute;
    top: 6px;
    left: -10px;
    @include media-breakpoint-up(md){
      width: 3px;
      height: 17px;
      top: 5px;
      left: -14px;
    }
  }
}

.border-bottom--white {
  border-bottom: solid 36px $white;
}
.height-80 {
  height: 80px;
}

.height-100 {
  height: 100px;
}

.height-120 {
  height: 120px;
}
.height-140 {
  height: 140px;
}
.height-220 {
  height: 220px;
}
.height-200 {
  height: 200px;
}
.height-180 {
  height: 180px;
}
.height-300 {
  height: 300px;
}
.height-md-300 {
  @include media-breakpoint-up(lg) {
    height: 300px;
  }
}
.height-md-350 {
  @include media-breakpoint-up(lg) {
    height: 350px;
  }
}
.height-md-250 {
  @include media-breakpoint-up(md) {
    height: 250px;
  }
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.hidden {
  opacity: 0;
}

.text-underline {
  text-decoration: underline;
}
.lh-1-1 {
  line-height: 1.125;
}


.line-title {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
  span {

  }
} 

.noScroll {
  overflow: hidden;
  height: 100%;
}

.relative {
  position: relative;
}

.font-mobile-tiny {
  @include media-breakpoint-down(xl) {
    font-size: 0.72rem!important;
  }
}

.font-mobile-28 {
  @include media-breakpoint-down(md) {
    font-size: 1.75rem;
  }
}
.font-mobile-16 {
  @include media-breakpoint-down(md) {
    font-size: 1rem !important;
    line-height: normal !important;
  }
}
.font-mobile-18 {
  @include media-breakpoint-down(md) {
    font-size: 1.125rem !important;
    line-height: normal !important;
  }
}

.retach-place-2 {
  position: relative;
  z-index: 8;
}

.h-lh-1 {
  line-height: 1.125!important;
}

.p-0-mobile {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.font-mobile-1-2 {
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;;
  }
}

.special-title {
  margin-bottom: .25rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.51875rem;
    
  }
}