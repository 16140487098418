.callBox {

}

.callBox-title {
    font-weight: 300;
    padding: 1rem 1rem 1rem 1rem;
    text-align: center;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        padding-top: 0.75rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 4rem 4rem;
        font-size: 2rem;
    }
    strong {
        font-weight: 700;
    }
}

.callBox-imgBg {
    position: relative;
    color: $white;
    overflow: hidden;
    .callBox-container {
        position: absolute;
        width: 100%;
        top: 52%;
        transform: translateY(-50%);
        @include media-breakpoint-up(sm) {
            top: 58%;
        }
    }
    .callBox-title {
        font-weight: 600;
        margin-bottom: .25rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
            padding: 0;
            margin-bottom: 2.75rem;
        }
    }
    &:after {
        display: block;
        content: '';
        padding-bottom: 59.75%;
        @media only screen and (max-width:23.625em) { 
            padding-bottom: 70.75%;
        }
        @include media-breakpoint-up(sm) {
            padding-bottom: 57.75%;
        }
        @include media-breakpoint-up(md) {
            padding-bottom: 28.75%;
        }
        @include media-breakpoint-up(lg) {
        }
    }

}

.callBox-imgBg-image {
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    height: 100%;
    max-width: none;
    @include media-breakpoint-up(md) {
        width: 100%;
        height: auto;
    }
}

.callBox-imgBg-position1 {
    @include media-breakpoint-up(md) {
        top: -14%;
    }
}

.callBox-imgBg-position2 {
    bottom: 0%;
    top: auto;
    @include media-breakpoint-up(md) {
        bottom: -14%;
        top: auto;
    }
}

.callBox-link {
    border-top: 4px solid $colorBrandAqua;
    font-weight: bold;
    font-size: 1.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    @include media-breakpoint-down(sm) {
        display: block;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
        min-width: 400px;
    }
}

.callBox-footnote {
    position: absolute;
    z-index: 3;
    bottom: 10px;
    right: 10%;
    @include media-breakpoint-down(md) {
        //display: none;
        right:50%;
        margin-right: -72px;
    }
}