// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
    cursor: pointer;
    transition: all 0.10s ease-in-out;
    border-radius: 0;
    display: inline-block;
    font-family: 'Roboto Condensed', sans-serif;
    padding-top: $spacer*.72;
    padding-bottom: $spacer*.72;
    white-space: normal;
    position: relative;
    font-size: 1.25rem;

}

.btn-orange {
    background-color: $colorBrandOrange;
    color: $white;
    &:hover {
        background-color: $colorBrandPurple;
        color: $white; 
    }
}
.btn-purple {
    background-color: $colorBrandPurple;
    color: $white;
    &:hover {
        background-color: $colorBrandOrange;
        color: $white;
    }
}

.btn-submit {
    font-size: 1.40rem;
    min-width: 260px;
    border-bottom: 4px solid $colorBrandAqua;
    font-weight: bold;
    padding-top: 0.45rem;
    padding-bottom: 0.45rem;;
    
}

.btn-pageBottom {
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
    //margin-top: 2.5rem;
    border-top: 4px solid $colorBrandPurple;
    font-size: 1.40rem;
    line-height: normal;
    text-align: left;
    font-weight: 100;
    strong {
        font-weight: bold;
    }
    &:after {
        content: '';
        width: 0;
        height: 0;
        border: solid white;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 9px;
        display: inline-block;
        margin-left: 10px;
        transform: rotate(-45deg);
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -10px;
    }
    @include media-breakpoint-up(lg){
        padding: 1.5rem 2.5rem 1.5rem 2rem;
        //font-size: 1.625rem;
        min-width: 500px;
        text-align: center;
    }
}

.btn-download {
    position: relative;
    font-weight: 600;;
    &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -12px;
        background: url(__('/img/content-images/download_icon.svg'));
        width: 20px;
        height: 20px;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    

}

.btn-fancyNext {
    font-size: 1.25rem;
    font-weight: 600;
    min-width: 290px;
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    padding-right: 40px;
    padding-left: 40px;
    line-height: normal;
    &.btn-orange {
        border-top: 4px solid $colorBrandPurple;
        &:hover {
            background-color: $colorBrandPurple;
        }
    }
    &:after {
        content: '';
        width: 0;
        height: 0;
        border: solid white;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 8px;
        display: inline-block;
        margin-left: 10px;
        transform: rotate(-45deg);
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -10px;;
    }
    &:hover {
        color: $white;
    }
}
.btn-arrow {
    padding-right: 3rem;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        display: inline-block;
        margin-left: 10px;
        transform: rotate(-45deg);
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -8px;;
    }
}

.sitemap-link {
    color: $colorBrandAqua;
    font-weight: bold;
    border-bottom: 2px solid $colorBrandAqua;
    &:hover {
        color: $colorBrandPurple;
        border-bottom: 2px solid $colorBrandPurple;
        text-decoration: none;
    }
}

