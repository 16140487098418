#survey {
  //text-align: center;

  select {
    //margin: 0 auto;
  }
  .btn-arrow {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  .question7 {
    text-align: left;
  }

  .form-radio-label {
    text-transform: none;

    &.uppercase { text-transform: uppercase; }
  }

  .form-check-inline { margin-right: 1.5em; }

  input[type=checkbox]+label:before { vertical-align: middle; }
}