// -----------------------------------------------------------------------------
// This file contains all icon codes
// -----------------------------------------------------------------------------
.fontello-cancel:before { content: '\e803'; } /* '' */
.fontello-minus:before { content: '\e804'; } /* '' */
.fontello-plus:before { content: '\e802'; } /* '' */
.fontello-search:before { content: '\e805'; } /* '' */
.fontello-right-open:before { content: '\e800'; }
.fontello-left-open:before { content: '\e801'; }
.fontello-play:before { content: '\e806'; }
.fontello-play-circled:before { content: '\e807'; }
.fontello-right-dir:before { content: '\e808'; }
