// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footerMain {
   position: relative;
   //margin-top: $spacer*2;
   padding-bottom: $spacer*2;
   font-size: $footerMain-size;
   color: $footerMain-color;
   background: $footerMain-background;
   font-family: $headings-font-family;
   @include media-breakpoint-up(lg) {
     font-weight: 700;
   }
}
.footerMain-topLink {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacer/2;
  padding-bottom: $spacer/2;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: $footerMain-topLink-color;
  background: $footerMain-topLink-background;
  font-weight: bold;
  &:hover, &:active  {
    text-decoration: none;
    color: $footerMain-topLink-hover-color;
  }
  &:focus {
    //color: $footerMain-topLink-color;
  }
}
.footerMain-main {
   display: flex;
   margin-bottom: $spacer;
   padding-top: $spacer*1.5;
   flex-wrap: wrap;
   

   @include media-breakpoint-up(sm) {
      padding-top: $spacer*3;
      padding-bottom: $spacer;
      flex-direction: row;
      justify-content: space-between;
   }

   @include media-breakpoint-up(lg) {
      align-items: flex-end;

   }

   p {
    font-size: 1rem;
   }
}

.footerMain-column {
  flex: 0 0 100%;
  @include font-size(12px);
  margin-bottom: 30px;

  @include media-breakpoint-up(lg){
    margin-bottom: 0;
    flex: 0 0 60%;
  }

  & + .footerMain-column {
    margin-bottom: 0;

    @include media-breakpoint-up(lg){
      flex: 0 0 40%;
    }
  }
}


.footerMain-inner {
   max-width: $maxWidth;
   margin-right: auto;
   margin-left: auto;
   padding-right: 15px;
   padding-left: 15px;
}

.footerMain-logo {
   padding-bottom: $spacer*.75;
   text-align: right;
   width: 160px;

   @include media-breakpoint-up(sm) {
     padding-left: 0;
      margin: auto;
      margin-right: 0;
      max-width: 197px;
   }

   @include media-breakpoint-up(lg) {
      max-width: 197px;
      width: calc(75%);
   }

   .footerMain-logoLink {
      display: block;
      width: calc(120%);
      font-weight: normal;
      margin: 0;
      text-align: left;

      @include media-breakpoint-up(lg){
        margin-left: auto;
        margin-right: 0;
        width: calc(75%);
      }

      img {
          margin: auto;
          text-align: right;
          max-width: 100%;
          width: calc(100%);
      }
   }
}

.footerMain-nav {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin: 0 0 1.75rem 0;
  padding: 0;
}

.footerMain-navItem {
   display: inline-block;
   font-size: 14px;
   font-weight: 400;

   @include media-breakpoint-up(sm) {
     font-size: 14px;
   }

   &::after {
    content: " | ";
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
   }

   &:last-child{
      &::after {
        display: none;
      }
   }
}

.footerMain-navLink {
   color: $footerMain-navLink-color;
   display: inline-block;
   text-decoration: none;

   &:hover {
    color: $colorBrandBlueLightest;
    text-decoration: none;
   }
}

.footerMain-topLinkIcon {
   margin-left: $spacer/2;
   color: $colorBrandOrange;
   font-size: 1.5rem;
}

.footerMain-collateral {
  font-weight: normal;
  @include font-size(12px);

  @include media-breakpoint-up(lg){
    @include font-size(14px);
  }

   p {
      margin-bottom: 0;
   }

   &.copy {
    @include media-breakpoint-up(lg){
      text-align: right;
    }
   }
}
