.sign-up {
    // display: flex;
    // flex-flow: column wrap;

    @include media-breakpoint-up(lg){
       // flex-direction: row wrap;
    }
    .panel {
      h2 {
          color: $colorBrandBlueLight;
      }

      p {
          color: $colorBrandBlueLight;
      }

      a {

          @include media-breakpoint-up(lg){
              flex-flow: row wrap;
          }

          &:hover {
              background-color: $colorButtonHover;
          }
      }

      .btn {
          display: flex;
          @include font-size(24px);
          padding-left: 0;
          position: relative;

          @include media-breakpoint-up(lg){
              margin-top: -20px;
          }

          &:hover { color: $colorBrandBlueLight; }

          .label {
              bottom: -10px;
              @include font-size(16px);
              left: 0;
              position: absolute;
          }
      }
    }





    &-content {
        flex: 0 0 100%;

        @include media-breakpoint-up(lg){
            border-right: 2px solid $colorBrandBlueLight;
            flex: 0 0 70%;
            max-width: 70%;
            padding-left: 65px;
            padding-right: 65px;
        }
    }

    &-button {
        flex: 0 0 100%;

        @include media-breakpoint-up(lg){
            align-self: center;
            flex: 0 0 30%;
            max-width: 30%;
            padding-left: 50px;
        }
    }
}


// This maybe missing from this version so I added it from dev
.panel.sign-up {
  border-bottom: 2px solid $colorBrandBlueLight;
  border-top: 2px solid $colorBrandBlueLight;
  .btn-arrow::after {
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -5px;
  }
}
.panel.sign-up a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    text-decoration: none;
}

@media (min-width: 992px) {
  .panel.sign-up a {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  }
}


.panel.sign-up-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}
@media (min-width: 992px) {
  .panel.sign-up-content {
      border-right: 2px solid #48a8c0;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 70%;
      flex: 0 0 70%;
      max-width: 70%;
      padding-left: 65px;
      padding-right: 65px;
  }
}

.panel.sign-up h2 {
    color: #48a8c0;
}
.panel.sign-up p {
    color: #48a8c0;
}

.panel.sign-up-button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}
@media (min-width: 992px) {
  .panel.sign-up-button {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
      max-width: 30%;
      padding-left: 50px;
  }
}

.panel.sign-up .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 24px;
    font-size: 1.5rem;
    padding-left: 0;
    position: relative;
}

@media (min-width: 992px) {
  .panel.sign-up .btn {
      margin-top: -20px;
  }
}

.panel.sign-up .btn .label {
    bottom: -10px;
    font-size: 16px;
    font-size: 1rem;
    left: 0;
    position: absolute;
}

.panel.sign-up a:hover {
    background-color: #e9f6f6;
}

.panel.sign-up .btn:hover {
    color: #48a8c0;
}
