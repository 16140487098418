.form {
    color: $colorBrandGray;
    @include font-primary;
    .full-form {
        height: 0;
        opacity: 0;
        visibility: hidden;
        &.active {
            height: auto;
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease-in;
        }
    }
    .form-dependent {
        height: 0;
        opacity: 0;
        visibility: hidden;
        &.active {
            height: auto;
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease-in;
        }
    }
    .form-label,
    label {
        color: $colorBrandGray;
        font-weight: 600;
        margin-bottom: $spacer*.5;
    }
    .form-check-label {
        font-weight: 300;
        font-family: 'Roboto Condensed', sans-serif;
        span {
            position: relative;
            top: 12px;
        }
    }
    .form-note {
        font-weight: normal;
    }
    .form-check-input {
        margin-left: 0;
    }
    .form-error {
        display: none;
        color: #ff0000;
        font-weight: 600;
        font-size: 1.125rem;
        &.active {
            display: block;
            opacity: 1;
        }
    }
    .error-spacing label {
        margin-top: 19px;
    }
    label+.form-error {
        margin-top: -10px;
        margin-bottom: 5px;
    }
    .form-check-label+.form-error {
        position: relative;
        top: -15px;
        left: 55px;
    }
    small.form-text {
        margin-bottom: 20px;
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    select,
    .select {
        border-radius: 0;
        border: 2px solid $colorBrandGray;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
        .form-row & {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }
    input[type=text],
    input[type=email], input[type=tel] {
        height: 46px;
    }
    select {
        //background-image: url(__("/img/template-images/icon-select-arrow.png"));
        background-repeat: no-repeat;
        background-position: right center;
        -webkit-appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
    .form-row {
        input[type=text],
        input[type=email],
        input[type=tel],
        select {
            width: 100%;
        }
    }
    select {
        height: auto !important;
    }
    input[type=radio],
    input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .form-radio-label {
        // border: 2px solid $colorBrandBlueLightest;
        // cursor: pointer;
        // @include font-size(24px);
        // padding: 10px 15px;
        //text-transform: uppercase;
    }
    // .w-33 {
    //     width: 100%;
    //     .form-radio-label {
    //         line-height: 1em;
    //         text-align: center;
    //         text-transform: none;
    //         width: 100%;
    //     }
    //     @include media-breakpoint-up(lg) {
    //         max-width: 32%;
    //         width: 32%;
    //         .form-radio-label {
    //             align-items: center;
    //             display: inline-flex;
    //             flex: 0 0 100%;
    //             flex-flow: column wrap;
    //             justify-content: center;
    //             max-width: 100%;
    //             min-height: 75px;
    //             width: 100%;
    //             &.is-ie {
    //                 display: inline-block;
    //             }
    //         }
    //     }
    // }
    input[type=radio]:active~label,
    input[type=checkbox]:active~label {
        opacity: 1;
    }
    input[type=radio]:checked~label,
    input[type=checkbox]:checked~label {
        background-color: $colorBrandBlueLight;
        //color: #FFF;
        opacity: 1;
    }
    .form-checkbox label, .form-radio label {
        //color: $colorBrandBlueLight;
        font-weight: 600;
        &.uppercase {
            text-transform: uppercase;
        }
    }
    .form-checkbox input[type=checkbox] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        &+label {
            position: relative;
            cursor: pointer;
            padding: 0;
        } // Box.
        &+label:before {
            border: 2px solid $colorBrandGray;
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 48px;
            height: 48px;
            background: white;
        } // Box hover
        &:hover+label:before {
            //background: $colorBrandBlueLight;
        } // Box focus
        &:focus+label:before {
            //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        } // Box checked
        &:checked+label:before {
            background: $colorBrandOrange;
            border-color: $colorBrandOrange;
        } // Checkmark. Could be replaced with an image
        &:checked+label:after {
            //background-image: url(__('/img/template-images/icon-checkbox.png'));
            background-repeat: no-repeat;
            background-position: center;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 48px;
            height: 48px;
        }
        &.no-bg {
            &:checked+label {
                background: none;
                //color: $colorBrandBlueLight;
            }
        }
    }


    .form-radio input[type=radio] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        &+label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        } // Box.
        &+label:before {
            border: 2px solid $colorBrandGray;
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 32px;
            height: 32px;
            background: white;
            border-radius: 50%;
        } // Box hover
        &:hover+label:before {
            //background: $colorBrandBlueLight;
        } // Box focus
        &:focus+label:before {
            //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        } // Box checked
        &:checked+label:before {
            background: $colorBrandOrange;
            border-color: $colorBrandOrange;
        } // Checkmark. Could be replaced with an image
        &:checked+label:after {
            //background-image: url(__('/img/template-images/icon-checkbox.png'));
            background-repeat: no-repeat;
            background-position: center;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 48px;
            height: 48px;
        }
        &.no-bg {
            &:checked+label {
                background: none;
                //color: $colorBrandBlueLight;
            }
        }
    }

    
    .privacy-notice {
        border: 2px solid $colorBrandPurple;
        padding: $spacer $spacer*2;
        max-height: 400px;
        overflow-y: auto;
        .btn {
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;
            text-align: center;
            width: auto;
        }
        
        li{
            padding-bottom: 12px;
        }
    }
    .hidden {
        height: 0;
        opacity: 0;
        visibility: hidden;
        &.active {
            height: auto;
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease-in;
        }
    }
    .btn {
        width: auto;
    }
    .select {
        max-width: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        height: 46px;
        padding: 5px 30px 5px 8px;
        background-color: $white;
        cursor: pointer;
        animation: .25s z-out forwards;
        z-index: 200;
        @include media-breakpoint-up(lg) {
            width: 100%;
            &.select-lg-50 {
                max-width: 50%;
                width: 50%;
            }
        }
    }
    .select.active {
        animation: .25s z-in forwards;
    }
    .select.active:after {
        //top: -5px;
        //transform: rotate(180deg);
        background-color: $colorBrandOrange;
    }
    .select-selected::selection {
        color: inherit;
        background: transparent;
    }
    .select-selected {
        font-weight: bold;
        font-size: 1.125rem;
        font-family: 'Roboto Condensed', sans-serif;
        color: $colorBrandPurple;
    }
    .select:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 0 9px 16px;
        border-color: transparent transparent transparent #ffffff;
        display: inline-block;
        margin-left: 10px;
        position: absolute;
        right: 14px;
        top: 50%;
        margin-top: -9px;
        z-index: 2;
        transform: rotate(90deg);
    }
    .select:after {
        //background-image: url(__("/img/template-images/icon-select-arrow.png"));
        background-repeat: no-repeat;
        background-position: right center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 48px;
        height: 42px;
        margin: 0;
        content: '';
        background-color: $colorBrandPurple;
    }
    .select-dropdown {
        position: absolute;
        top: calc(100% + 2px);
        right: -2px;
        left: -2px;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        min-width: 100%;
        background: #FFF;
        border: 2px solid #444;
        border-top: 0;
        max-height: 175px;
        left: -9999em;
        overflow: auto;
        width: calc(100% + 3px);
        height: 175px;
        z-index: 500;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: bold;
        font-size: 1.125rem;
    }
    .select.active .select-dropdown {
        left: -2px;
    }
    .select.select-short .select-dropdown {
        height: 75px;
    }
    .select.select-auto .select-dropdown {
        height: auto;
    }
    .select.select-lg-50 .select-dropdown {
        @include media-breakpoint-up(lg) {
            //max-width: calc(50% + 3px);
            //width: calc(50% + 3px);
        }
    }
    .select-dropdown .option:hover {
        color: #fff;
        background: $colorBrandOrange;
    }
    .select-dropdown .option.disabled {
        pointer-events: none;
        color: #555555;
        border-left-color: #555555;
        display: none;
    }
    .select-dropdown .option.selected {
        // background: $colorBrandBlueLight;
        // color: #FFF;
        // font-weight: 700;
    }
    .select-dropdown .option,
    .select-dropdown .category {
        padding: 5px 10px;
        transition: .15s ease-out;
        color: #333333;
        background: transparent;
    }
    .select-dropdown .option::selection,
    .select-dropdown .category::selection {
        color: inherit;
        background: transparent;
    }
}

// Scrollpane Overrides
.jspTrack {
    background: $colorBrandBlueLightest;
}

.jspDrag {
    background: $colorBrandBlueLight;
}

.jspArrow {
    background: #cfeaea;
    &.jspArrowUp::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #FFF;
        content: " ";
        display: block;
        height: 0;
        left: 3px;
        position: relative;
        top: 5px;
        width: 0;
    }
    &.jspArrowDown::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #FFF;
        content: " ";
        display: block;
        height: 0;
        left: 3px;
        position: relative;
        top: 5px;
        width: 0;
    }
}

.jspVerticalBar {
    z-index: 600;
}

// Radios and checkboxes on same line
.form-check-inline {
    display: inline-block;
    margin-right: $form-check-inline-margin-x;
    width: 120px;
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
        width: 130px;
    }
    
  
    .form-check-label {
      vertical-align: middle;
    }
  }
  
//confirmation
.confirmation-content {
    display: none;

    &.active {
        display: block;
    }
}