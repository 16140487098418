.arrowChart {
    position: relative;
    height: 120px;
    &:before {
        content: '';
        width: 50px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        background: url(__("/img/content-images/paper_texture_pattern.jpg"))
    }
}
.arrowChart-img {
    height: 100%;
    position: absolute;
}
.arrowChart-img-1 {
    z-index: 1;
    left: 0;
    margin-left: 10px;
}
.arrowChart-img-2 {
    left: 0;
    z-index: 0;
}

.arrowChart-img-0 {
    z-index: 5;
    position: absolute;
    left: 0;
}