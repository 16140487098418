.ctaBox {
    &-feature {
        background-color: #ebebeb;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            padding-bottom: 56%;
            
            display: block;
        }

    }
    &-featureTitle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        color: $white;
        text-shadow: 2px 2px 2px #000000;
        font-size: 1.75rem;
        padding: 0 2rem;
        font-weight: 1.375rem;
        font-family: 'Roboto Condensed', sans-serif;
    }
    &-featureImage {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }
    &-detail {

    }
    &-detailAnchor {
        display: block;
        border-top: 4px solid $colorBrandAqua;
        font-size: 1.125rem;
        font-weight: bold;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 6px;
            display: inline-block;
            margin-left: 10px;
            transform: rotate(-45deg);
            top: 1px;
            position: relative;
        }

    }
}

.ctaDownload {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md){
    }
    @include media-breakpoint-up(lg){
        margin-bottom: 3.5rem;
        padding-left: 20%;
        padding-right: 20%;
    }
    .ctaDownload-title {

    }
    .ctaDownload-image {
        width: 100%;
        margin-bottom:1rem;
        outline:2px solid $colorBrandPurple;
        outline-offset: -2px;
    }
    .ctaDownload-detail {

    }
    .ctaDownload-link {
        display: block;
        border-top: 4px solid $colorBrandAqua;
    }
}

.ctaFaq {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    text-align: center;
    align-items: center;
    //margin-bottom: 2rem;
    @include media-breakpoint-up(md){
    }
    @include media-breakpoint-up(lg){
        //margin-bottom: 3.5rem;
    }
    .ctaFaq-title {

    }
    .ctaFaq-image {
        margin-bottom: 1rem;
    }
    .ctaFaq-detail {
        margin-bottom: 1rem;
    }
    .ctaFaq-link {
        display: block;
        border-top: 4px solid $colorBrandPurple;
        margin-top:auto;
        width: 100%;
        min-height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 510px;
    }
}


.ctaPartner-imageHolder {
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    align-items: center;
    .ctaPartner-image {
        max-width: 280px;
    }
    .ctaPartner-image--small {
        max-width: 210px;
    }
}

.ctaPartner {
    @include media-breakpoint-up(md){
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include media-breakpoint-up(lg){
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.ctaPartner-title {
    text-decoration: underline;
    color: $colorBrandAqua;
    font-size: 1.25rem;
    margin-bottom: 0.35rem;
    display: block;
}