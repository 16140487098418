// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.headerMain {
	position: relative;
	z-index: 9;

	@include media-breakpoint-up(lg) {
		transition: all .5s;

		.header-sticky & {
			/*position: fixed;
		 z-index: 300;
		 top: 0;
		 right: 0;
		 left: 0;
		 animation: fadein .5s;
		 border-bottom: 1px solid $colorBrandBlueDark;
		 .headerMain-util {
			display: none;
		 }*/
		}
	}
}


.headerMain-main {
	background: $headerMain-main-background;
}

.headerMain-mainInner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: $maxWidth;
	margin-right: auto;
	margin-left: auto;

	.header-sticky & {
		align-items: center;
	}

	@include media-breakpoint-up(lg) {
		align-items: flex-start;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.headerMain-util {
	background: #FFF;
	order: 0;
	flex: 0 0 100%;

	@include media-breakpoint-up(lg) {
		flex: 0 0 60%;
		margin-right: 11px;
		margin-top: 20px;
	}
}

.headerMain-utilInner {
	display: flex;
	flex-wrap: wrap;
	max-width: $maxWidth;
	margin-right: auto;
	margin-left: auto;

	@include media-breakpoint-up(lg) {
		justify-content: flex-end;
		margin-bottom: 18px;
	}
}

.headerMain-navUtil {
	//display: none;
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: $spacer;
	margin-top: $spacer;
	width: 100%;

	@include media-breakpoint-up(lg) {
		flex-wrap: nowrap;
		justify-content: flex-end;
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: $headerMain-navUtil-size;
	}
}

.headerMain-navUtilItem {
	display: flex;
	flex: 0 0 25%;
	text-align: center;
	align-items: center;
	border-right: 1px solid $colorBrandBlueLight;
	

	@include media-breakpoint-down(lg) {
		&.no-border-sm {
			border-right: 0;
		}
	}
	@include media-breakpoint-up(lg) {
		
	}

	&:last-child {
		border-right: 0;
	}

	&:first-of-type {
		text-align: left;

		.headerMain-navUtilLink {
			@include media-breakpoint-up(lg) {
				padding-left: 0 !important;
			}
		}
	}

	@include media-breakpoint-up(md) {
		width: auto;
		flex-basis: auto;
	}

	@include media-breakpoint-up(lg) {}
}

.headerMain-navUtilLink {
	display: block;
	cursor: pointer;
	text-decoration: none;
	color: $colorBrandBlueLight;
	@include font-size(10px);
	@include font-primary;
	line-height: 1.25em;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding: 0 5px;
	text-decoration: underline;

	&:hover,
	&:active {
		color: $colorBrandPurple;
	}

	@include media-breakpoint-up(md) {
		align-items: center;
		@include font-size(11px);
		padding: 0 10px;
	}

	@include media-breakpoint-up(lg) {
		flex: 0 1 auto;
	}
}

.headerMain-navUtilText {
	@include font-size(10px);
	@include font-primary;
	line-height: 1.25em;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding: 0 5px;
	margin-bottom: 0;
}

.headerMain-logo {
	flex: 0 0 42%;
	order: 1;
	margin-left: 10px;

	@include media-breakpoint-up(lg) {
		flex: 0 0 25%;
		order: 0;
		margin-left: 0;
	}
}

.headerMain-logoLink {
	display: block;

	@include media-breakpoint-up(lg) {
		left: 8px;
		position: relative;
		z-index: 100;
	}
}

.headerMain-logoImg {
	display: inline-block;
	max-width: 100%;
	height: auto;
	width: 240px;

	@include media-breakpoint-up(sm) {
		width: 240px;
	}

	.header-sticky & {
		width: 144px;
	}
}

.headerMain-SubContent {
	display: none;
	&.is-moved {
		display: block;
	}
	@include font-primary;
	max-width: $maxWidth;
	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: flex-end;
	}
	&Btn {
		border-bottom: 2px solid $white;
		background-color: $colorBrandOrange;
		color: #FFF;
		display: block;
		font-family: $font-family-alt;
		@include font-size(18px);
		position: relative;
		padding: $spacer*0.85 1rem;
		text-align: center;
		line-height: normal;

		@include media-breakpoint-up(lg) {
			border-bottom: none;
			padding: $spacer*.25 $spacer*3;
			border-right: 1px solid #fff;
			font-weight: 600;
			@include font-size(14px);
		}
		&:last-child {
			border-bottom: none;
		}
		&:hover, &.active {
			background-color: $colorBrandRed;
			text-decoration: none;
			color: #fff;
		}
		&:after {
			@include media-breakpoint-down(md) {
				content: '';
				width: 0;
				height: 0;
				border: solid white;
				border-width: 0 2px 2px 0;
				display: inline-block;
				margin-left: 10px;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -5px;
				padding: 4px;
				transform: rotate(-45deg);
			}
			@include media-breakpoint-up(lg) {
				//content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 0 4px 8px;
				border-color: transparent transparent transparent #ffffff;
				display: inline-block;
				margin-left: 10px;
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -2px;
			}
		}
	}
}


.headerMain-navMainToggleContainer {
	align-items: center;
	align-self: center;
	display: inline-flex;
	flex: 0 0 13%;
	flex-direction: column;
	justify-content: center;
	margin-left: 15px;
	order: 3;
	position: relative;

	@include media-breakpoint-up(lg) {
		display: none;
	}
}


.headerMain-navMainToggle {
	position: relative;
	display: inline-block;
	width: 24px;
	height: 16px;

	&.is-active {
		height: 24px;
	}
}

.headerMain-navMainToggleLabel {
	color: $colorBrandPurple;
	@include font-primary;
	@include font-size(12px);
	font-weight: 600;
	position: relative;
	top: 5px;
	display: none;

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.headerMain-navMainToggleLine {
	position: absolute;
	display: block;
	width: 24px;
	height: 3px;
	-o-transition: all .4s;
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	transition: all .4s;
	border-radius: 5px;
	background: map-get(map-get($palettes, 'colorBrandPurple'), 'base');

	&:first-child {
		top: 0;
		width: 24px;
		right: 0;

		.is-active & {
			transform: translateY(12px) translateX(0) rotate(45deg);
			//background: map-get(map-get($palettes, 'colorBrandOrange'), 'base');
		}
	}

	&:nth-child(2) {
		top: 50%;

		.is-active & {
			opacity: 0;
		}
	}

	&:last-child {
		top: 100%;
		width: 24px;
		right: 0;

		.is-active & {
			transform: translateY(-12px) translateX(0) rotate(-45deg);
			//background: map-get(map-get($palettes, 'colorBrandOrange'), 'base');
		}
	}
}

.headerMain-mainNavigation {
	height: 0;
	max-height: 0;
	opacity: 0;
	visibility: hidden;

	@include media-breakpoint-up(lg) {
		height: auto;
		opacity: 1;
		visibility: visible;
		background: $colorBrandPurple;
		max-height: none;
		border-top: 2px solid $colorBrandAqua;
	}

	&.is-open {
		max-height: 880px;
		opacity: 1;
		transition: all .1s linear;
		visibility: visible;
	}

	ul {
		
		//flex-wrap: wrap;
		list-style: none;
		margin: 0;
		padding: 0;
		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}

	&Item {
		//flex: 0 0 100%;
		border-bottom: 2px solid $white;
		line-height: normal;

		@include media-breakpoint-up(lg) {
			flex: 1;
			border-left: 1px solid #fff;
			border-bottom: none;

		}

		&:last-child {
			@include media-breakpoint-up(lg) {
				border-right: 1px solid #fff;
			}
		}

		&Link {
			color: $white;
			background: $colorBrandPurple;
			display: block;
			font-family: $font-family-alt;
			@include font-size(18px);
			position: relative;
			padding: $spacer*0.85 2rem $spacer*0.85 2rem;
			text-align: center;
			line-height: normal;
			@include media-breakpoint-up(lg) {
				@include font-size(16px);
				text-align: center;
				height: 100%;
				vertical-align: middle;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: $spacer*.55 2rem;
				text-align: left;
				font-weight: bold;
				div {
					text-align: center;
				}
			}

			&:hover {
				background-color: $colorBrandAqua;
				color: #fff;
				text-decoration: none;
				@include media-breakpoint-up(lg) {
					background-color: $colorBrandOrange;
				}
			}

			&.active {
				background-color: $colorBrandAqua;
				color: #FFF;
				@include media-breakpoint-up(lg) {
					background-color: $colorBrandOrange;
				}
			}
			&:after {
				@include media-breakpoint-down(md) {
					content: '';
					width: 0;
					height: 0;
					border: solid white;
					border-width: 0 2px 2px 0;
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -5px;
					padding: 4px;
					transform: rotate(-45deg);
				}
				@include media-breakpoint-up(lg) {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 0 4px 8px;
					border-color: transparent transparent transparent #ffffff;
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -2px;
				}
			}
		}
	}
}


.headerMain-mainNavigationInner {
	margin-left: auto;
	margin-right: auto;
	max-width: 1170px;
	
	@include media-breakpoint-up(lg) {
		padding-left: 15px;
		padding-right: 15px;
	}

}







.headerMain-navSub {
	width: 100%;

	@include media-breakpoint-up(lg) {
		position: absolute;
		//background-color: #EBEBEB;

	}

}

.headerMain-navSubLink {
	padding-top: 10px;
	padding-bottom: 10px;
	color: $colorBrandPurple;
	background-color: #fff;
	display: block;
	padding-left: 10px;
	padding-right: 15px;
	border-bottom: 1px solid $colorBrandPurple;
	font-weight: bold;
	line-height: 1;
	font-size: 1rem;
	text-align: center;
	position: relative;
	padding-right: 1.5rem;
	
	&:after {
		content: '';
		width: 0;
		height: 0;
		border: solid $colorBrandPurple;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 4px;
		display: inline-block;
		transform: rotate(-45deg);
		position: absolute;
		right: 0.75rem;
		top: 50%;
		margin-top: -7px;
	}
	&:last-child {
		border-bottom: none;
	}

	&:active,
	&.active {
		text-decoration: none;
		background-color: $colorBrandOrange;
		color: #fff;
	}

	&:hover {
		background-color: $colorBrandOrange;
		color: $white;
		text-decoration: none;

		@include media-breakpoint-up(lg) {}
	}
}

.dropdown {

	.headerMain-navSub {
		display: none;
		@include media-breakpoint-up(lg) {
			
		}
	}

	&:hover {
		.headerMain-navSub {
			@include media-breakpoint-up(lg) {
				display: block;
			}

		}
	}
}