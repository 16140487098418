.heroMain {
    position: relative;
    justify-content: center;
    overflow: hidden;
    @include media-breakpoint-up(md){
        display: flex;
    }
    &:after {
        content: '';
        display: none;
        @include media-breakpoint-up(md){
            display: block;
        }
    }
    &-container {
        @include media-breakpoint-up(md){
            position: absolute;
            height: 100%;
            left: 0;
            right: 0;
        }
    }
    .heroMain-outerBox {
        padding: 1rem;
        @include media-breakpoint-up(md){
            position: absolute;
            top:10%;
        }
    }
    .heroMain-innerBox {
        padding: 1rem;
        position: relative;

    }
    .heroMain-logo {

    }
    
    
}

.bg-wood {
    background-color: #472c17;
    background-image: url(__("/img/content-images/heros/0.0_hero_wood_grain_m.jpg"));
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 0;
    @include media-breakpoint-up(md){
        background-image: url(__("/img/content-images/heros/0.0_hero_wood_grain.jpg"));
    }
}
.bg-wood2 {
    background-color: #472c17;
    background-image: url(__("/img/content-images/heros/4.0_hero_wood_grain.jpg_m"));
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 0;
    @include media-breakpoint-up(md){
        background-image: url(__("/img/content-images/heros/4.0_hero_wood_grain.jpg"));
    }
}


.bg-leather {
    background-color: #472c17;
    background-image: url(__("/img/content-images/heros/1.0_hero_background_m.jpg"));
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 0;
    @include media-breakpoint-up(md){
        background-image: url(__("/img/content-images/heros/1.0_hero_background.jpg"));
    }
}

.heroMain-containedBg {
    z-index: 1;
    height: 100%;
}
.heroMain-containedBg-img {
    

}

.heroMain--home {
    &:after {
        padding-bottom: 44%;
        @include media-breakpoint-up(md){
            padding-bottom: 60%;
            max-width: 600px;
        } 
        @include media-breakpoint-up(lg){
            padding-bottom: 47%;
            max-width: 600px;
        }  
        @include media-breakpoint-up(xl){
            padding-bottom: 540px;
        }     
    }
    .heroMain-container {
        @include media-breakpoint-down(sm) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .heroMain-containedBg-img {
        //height: 37%;
        //right: -10px;
        //width: calc(100% - 15px);
        width: 100%;
        max-width: none;
        overflow: auto;
        margin: auto;
        position: absolute;
        //left: 55%;
        //-webkit-transform: translateX(-50%);
        //transform: translateX(-50%);
        
        @include media-breakpoint-up(sm){
            //height: 42%;
        }
        
        @include media-breakpoint-up(md){
            left: 60%;
            min-height:480px;
            height: auto;
            width: auto;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        @include media-breakpoint-up(lg){
            left: 50%;
        }
    }
    .heroMain-outerBox {
        background:none;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        z-index: 2;
        text-align: right;
        @include media-breakpoint-up(md){
            padding: 1rem;
            padding-top: 0;
            position: absolute;
            top:0;
            right: 0;
            width: 52%;
            height: 100%;
        }
        @include media-breakpoint-up(lg){
            width: 56%;
        }
    }
    .heroMain-innerBox {
        background-image: url(__("/img/content-images/heros/0.0_home_paper_scrap_m.png"));
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% 100%;
        
        padding: 1rem 0.75rem;
        padding-bottom: 3.75rem;
        position: relative;
        margin-top: 47%;
        margin-bottom: 0.5rem;
        left: 0;
        @include media-breakpoint-up(sm){
            margin-top: 47%;
            background-size: cover;
        }
        @include media-breakpoint-up(md){
            padding: 1rem 0.75rem;
            padding-right: 0;
            background:none;
            left: -6px;
            margin-top: 0;
            text-align: left;
            
        }
        @include media-breakpoint-up(lg){
            margin-right: 14%;
            padding: 1rem;
            background-image: none;
            
        }
        @include media-breakpoint-up(xl){
            margin-right: 18%;
            
        }

    }
    .heroMain-logoHolder {
        width: 100%;
        text-align: center;
        padding-left: 44%;
        padding-right: 1.25rem;
        position: absolute;
        top: 2.9rem;
        @include media-breakpoint-up(md){
            top: auto;
            position: relative;
            padding-left: 0;
            width: 50%;
            margin-top: 1.5rem;
        }
        @include media-breakpoint-up(md){
            width: 58%;
        }
    }
    .heroMain-logo {
        width: 100%;
        z-index: 2;
    }
    .heroMain-homeContent-img {
        width: 100%;
        @include media-breakpoint-up(md){
            width: 80%;
        }
        @include media-breakpoint-up(lg){
            width: 80%;
        }
        @include media-breakpoint-up(xl){
            width: 90%;
        }
        
    }
    .heroMain-stepNotice {
        line-height: normal;
        font-size: 0.75rem;
        font-weight: bold;
        margin-top: 1rem;
        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }
    }
}

.heroMain-cta {
    border-top: 4px solid $colorBrandPurple;
    font-weight: bold;
    bottom: 1.5rem;
    position: absolute;
    z-index: 3;
    left: 50%;
    width: 80%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    line-height: normal;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    font-size: 1.125rem;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        display: inline-block;
        margin-left: 10px;
        transform: rotate(-45deg);
        position: absolute;
        right: 1.25rem;
        top: 50%;
        margin-top: -7px;
    }
    @include media-breakpoint-up(md) {
        width: auto;
        padding: 1rem 3rem 1rem 2rem;
    }
    @include media-breakpoint-up(md){
        right: 35px;
        box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.37); 
        -webkit-transform: none;
        transform: none;
        left: auto;
    }
}


.heroMain--sub {
    &:after {
        padding-bottom: 38%;
        @include media-breakpoint-up(xl){
            padding-bottom: 500px;
        }   
    }
    .heroMain-container {
        @include media-breakpoint-down(sm) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .heroMain-containedBg-img {
        
        @include media-breakpoint-up(md){
            height: 100%;
            width: auto;
            max-width: none;
            overflow: auto;
            margin: auto;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
    .heroMain-outerBox {
        background-image: url(__("/img/content-images/heros/4.0_hero_paper_scrap.png"));
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% 100%;
        padding: 1rem 0.75rem;
        padding-bottom: 3rem;
        position: absolute;
        top: 0;
        width: 90%;
        margin-left: 5%;
        @include media-breakpoint-up(md){
            width: auto;
            margin-left: 0;
        }
        @include media-breakpoint-up(md){
            //position: relative;
            width: 44%;
            height: 80%;
            position: absolute;
            top:10%;
        }
        &.heroMain-outerBox--half {
            top: -5px;
            @include media-breakpoint-up(md){
                top: 10%;
                height: 50%;
            }
        }
    }
    .special-paperclip {
        position: relative;
        display: none;
        &:before {
            content: '';
            width: 22px;
            height: 22px;
            left: -9px;
            top: -46px;
            background-image: url(__("/img/content-images/heros/paper_clip.png"));
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            position: absolute;
            z-index: 4;
            @include media-breakpoint-up(md){
                width: 42px;
                height: 42px;
                left: -15px;
                top: -96px;
            }
            
        }
        @include media-breakpoint-up(lg){
            display: block;
        }
    }
    .heroMain-innerBox {
        background-image: url(__("/img/content-images/4.0_hero_paper_strip.png"));
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% 100%;
        width: 101%;
        left: -3px;
        padding: 1.5rem 2rem 1.75rem 2rem;
        position: relative;
        @include media-breakpoint-up(lg){
            padding: 1.5rem 2rem 1.75rem 4rem;
        }
        &:before {
            content: '';
            width: 22px;
            height: 22px;
            left: -9px;
            top: 23px;
            background-image: url(__("/img/content-images/heros/paper_clip.png"));
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            position: absolute;
            z-index: 4;
            @include media-breakpoint-up(lg){
                width: 42px;
                height: 42px;
                left: -15px;
                top: 22px;
            }
            
        }
        
    }
    .heroMain-logoHolder {
        text-align: center;
        margin: .5rem 0;
        @include media-breakpoint-up(xl){
            margin: 2rem 0;
            margin-left: -10px;
        }
    }
    .heroMain-logo {
        width: 76%;
        z-index: 2;
        @include media-breakpoint-up(xl){
            width: 82%;
        }
    }
    .heroMain-step {
        display: flex;
        font-size: 1rem;
        @include media-breakpoint-up(lg){
            font-size: 1.25rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 1.40625rem;
        }
    }
	.heroMain-stepLabel {
        margin-right: 10px;
        font-weight: bold;
        color: $colorBrandPurple;
    }
    .heroMain-stepTitle {
        font-weight: bold;
    }
}

.heroMain--sub .heroMain-innerBox.paperclip-2line:before {
    @include media-breakpoint-up(lg){
        top:30px;
    }
}