.styled-list {
    list-style: none;
    margin-left: 1rem;
    padding-left: 1rem;

    li:before {
        content: "●";
        color: $colorBrandOrange;
        display: inline;
        width: 1rem;
        margin-left: -1rem;
        margin-right: 0.5rem;
        font-size: 0.65rem;
        position: relative;
        top: -3px;
    }
}

.styled-list-purple {
    li:before {
        color: $colorBrandPurple;
    }
}

.styled-list-no-indent {
    padding-left: 0;
    margin-left: .5rem;
    li {
        padding-left: 2px;

        &:before {
            margin-left: -10px;
            margin-right: 3px;
        }
    }
}