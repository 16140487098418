.jumpBar {
    background-color: $colorBrandLightTan;
    font-size: 1rem;
    transition: all 0.5s ease;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    width: 100%;
    .jumpBar-container {

    }
    .jumpBar-navToggle {
        background-color: $colorBrandPurple;
        color: #fff;
        font-weight: bold;
        margin-right: 4px;
        position: relative;
        border: none;
        padding: 0.275rem 0.75rem;
        width: 100%;
        text-align: center;
        border-bottom: 3px solid $colorBrandPurple;
        
        @include media-breakpoint-up(lg) {
            display: none;
        }
        &:after {
            content: '';
            width: 0;
            height: 0;
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 6px;
            display: inline-block;
            margin-left: 10px;
            transform: rotate(45deg);
            position: relative;
            top: 0px;
        }
        &.active {
            border-bottom: 3px solid $white;
            &:after {
                transform: rotate(-135deg); 
                top: 5px;
            }
            
        }
    }
	.jumpBar-nav {
        height: 0;
        overflow: hidden;
        z-index: 5;
        position: absolute;
        width: 100%;
        @include media-breakpoint-up(lg) {
            display: flex;
            position: relative;
            height: auto;
        }
        &.active {
            height: auto;
        }
    }
	.jumpBar-navItem {
        &:nth-child(even) {
            .jumpBar-navLink {
                &.active {
                    //background-color: $colorBrandLightTan;
                }
            }
        }
    }
	.jumpBar-navLink {
        background-color: $colorBrandPurple;
        color: #fff;
        font-weight: bold;
        margin-right: 4px;
        position: relative;
        border: none;
        padding: 0.875rem 0.75rem 0.975rem;
        
        width: 100%;
        font-size: 1.125rem;
        line-height: 1.25;
        text-align: center;
        @include media-breakpoint-up(lg) {
            width: auto;
            padding-right: 2rem;
        }
        &:before {
            content: '';
            position: absolute;
            height: 3px;
            width: 100%;
            background: $white;
            bottom: 0;
            left: 0;
            display: block;
        }
        &:after {
            @include media-breakpoint-up(lg) {
                content: '';
                width: 0;
                height: 0;
                border: solid white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                display: inline-block;
                margin-left: 10px;
                transform: rotate(45deg);
                margin-bottom: 2px;
                position: absolute;
                right: 10px;
                top: 50%;
                margin-top: -5px;
            }
        }
        &.active {
            background-color: $colorBrandAqua;
            @include media-breakpoint-up(lg) {
                background: url(__("/img/content-images/paper_texture_pattern.jpg"));
                color: $colorBrandPurple;
                &.special-lightTan {
                    background: $colorBrandLightTan;
                }
                &.special-white {
                    background: $white;
                }
            }
            &:after {
                border: solid $colorBrandPurple;
                border-width: 0 2px 2px 0;
            }
            &:before {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }
        &:nth-child(even) {
            &.active {
                //background-color: $colorBrandLightTan;
            }
        }
    }

}
.fixed-nav .jumpBar {
    position: fixed;
    top: 0;
    z-index: 11;;

}

.jumpBar-container {
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.jumpBar-stickyTitles {
    display: none;
    background: url(__("/img/content-images/paper_texture_pattern.jpg"));
    @include media-breakpoint-up(lg) {
        &.active {
            display: block;
        }
    }
    
}

.jumpBar-stickyTitleItem {
    display: none;
    padding-top: 2rem;
    padding-bottom: 0rem;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-up(lg) {
        &.active {
            display: block;
        }
    }
    h2 {
        margin-bottom: 0;
    }
    
}