.box-purple {
    border: 3px solid $colorBrandPurple;
    margin-top: 2rem;
    text-align: center;
    margin-top: 4rem;
    .box-title {
        background-color: $colorBrandPurple;
        color: $white;
        display: inline-block;
        padding: 0.5rem 2rem;
        position: relative;
        top: -42px;
    }
}

.box-aqua {
    border: 3px solid $colorBrandAqua;
    margin-top: 2rem;
    text-align: center;
    margin-top: 4rem;
    .box-title {
        background-color: $colorBrandAqua;
        color: $white;
        display: inline-block;
        padding: 0.5rem 2rem;
        position: relative;
        top: -42px;
    } 
}

.border-flare-left {
    position: relative;
    &:before {
        content: '';
        background: url(__('/img/content-images/img_sketch_lines_left.png'));
        top: -15px;
        left: -15px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 78px;
        height: 43px;
        @include media-breakpoint-up(lg) {
            width: 156px;
            height: 86px;
            top: -15px;
            left: 0;
        }
    }
}

.border-flare-right {
    position: relative;
    &:after {
        content: '';
        background: url(__('/img/content-images/img_sketch_lines_right.png'));
        bottom: -10px;
        right: -15px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 78px;
        height: 43px;
        @include media-breakpoint-up(lg) {
            width: 156px;
            height: 86px;
            bottom: 0;
            right: 0;
        }
    }
}

