// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/*
 * black and white for mixing
 */
$colorWhite: #fff !default;
$colorBlack: #000 !default;
/*
* Define base brand colors and gray variables here and then add them to the map below
*/


// Pulled from Style-Guide_v2.pdf
$colorBrandPurple: #7d0049;
$colorBrandGray: #444444;
$colorBrandOrange: #f47b20;
$colorBrandBlueLight: #48a8c0;
$colorBrandBlueLightest: #b5e1e1;
$colorButtonHover: #e9f6f6;

$colorBrandBlueWhite: #ecf7f7;
$colorBrandBlueGray: #7f8282;


$colorBrandBlue: #6ec8ee;
//$colorBrandBlueLight: #e9f7fd;
$colorBrandBlueDark: #275776;
$colorBrandBlueDarkLight: #dfe6eb;
//$colorBrandOrange: #ffa400;
$colorBrandOrangeLight: #fff8ec;
$colorGray: #55575b;
$colorGrayLight: #aaabad;
$colorGrayLighter: #e6e5e5;
$colorGrayLightest: #f2f2f2;

$colorBrandTan: #d4cbc0;
$colorBrandLightTan: #f1f0ee;
$colorBrandAqua: #3992a8;
$colorBrandRed: #ec3600;
$colorBrandRed2: #da2d42;

$colorBrandAqua2: #45718e;

/*
* Place brand colors and gray in map for shades
* Helper classes are being generated by this sass map in styles/base/_helpers.scss
* as well as populating in pug/styleguide.pug
* USAGE:
	div {
		color: palette(colorGray,darker);
		background-color: palette(colorBrandBlue,lightest);
		border: 1px solid palette(colorBrandBlue,base);
	}
*/
$palettes: (
	colorBrandBlue: (
		lightest : mix($colorWhite, $colorBrandBlue, 75%),
		lighter  : mix($colorWhite, $colorBrandBlue, 55%),
		light    : mix($colorWhite, $colorBrandBlue, 25%),
		base     : $colorBrandBlue,
		dark     : mix($colorBlack, $colorBrandBlue, 25%),
		darker   : mix($colorBlack, $colorBrandBlue, 50%),
		darkest  : mix($colorBlack, $colorBrandBlue, 75%)
	),
	colorBrandBlueDark: (
		lightest : mix($colorWhite, $colorBrandBlueDark, 75%),
		lighter  : mix($colorWhite, $colorBrandBlueDark, 55%),
		light    : mix($colorWhite, $colorBrandBlueDark, 25%),
		base     : $colorBrandBlueDark,
		dark     : mix($colorBlack, $colorBrandBlueDark, 25%),
		darker   : mix($colorBlack, $colorBrandBlueDark, 50%),
		darkest  : mix($colorBlack, $colorBrandBlueDark, 75%)
	),
	colorBrandOrange: (
		lightest : mix($colorWhite, $colorBrandOrange, 85%),
		lighter  : mix($colorWhite, $colorBrandOrange, 55%),
		light    : mix($colorWhite, $colorBrandOrange, 25%),
		base     : $colorBrandOrange,
		dark     : mix($colorBlack, $colorBrandOrange, 25%),
		darker   : mix($colorBlack, $colorBrandOrange, 50%),
		darkest  : mix($colorBlack, $colorBrandOrange, 75%)
	),
	colorGray: (
		beyondlightest : mix($colorWhite, $colorGray, 95.4%),
		lightest : mix($colorWhite, $colorGray, 77.1%),
		lighter  : mix($colorWhite, $colorGray, 55%),
		light    : mix($colorWhite, $colorGray, 25%),
		base     : $colorGray,
		dark     : mix($colorBlack, $colorGray, 25%),
		darker   : mix($colorBlack, $colorGray, 50%),
		darkest  : mix($colorBlack, $colorGray, 75%)
	),
	colorBrandPurple: (
		beyondlightest : mix($colorWhite, $colorBrandPurple, 95.4%),
		lightest : mix($colorWhite, $colorBrandPurple, 77.1%),
		lighter  : mix($colorWhite, $colorBrandPurple, 55%),
		light    : mix($colorWhite, $colorBrandPurple, 25%),
		base     : $colorBrandPurple,
		dark     : mix($colorBlack, $colorBrandPurple, 25%),
		darker   : mix($colorBlack, $colorBrandPurple, 50%),
		darkest  : mix($colorBlack, $colorBrandPurple, 75%)
	)
);


/*
 * overall body font color
 */
$colorText: $colorGray;

/*
 * overall body font color
 */
$colorLinks:map-get(map-get($palettes, 'colorBrandBlue'), 'base');
$colorLinksHover:map-get(map-get($palettes, 'colorBrandOrange'), 'base');
/*
 * Generic border color for consistency
 */
$colorBorder:map-get(map-get($palettes, 'colorGray'), 'lighter');
/*
* Global max-width
*/
$maxWidth:1170px;

/*
* bootstrap overrides
*/
$container-max-widths: (
  sm: $maxWidth - 3px,
  md: $maxWidth - 2px,
  lg: $maxWidth - 1px,
  xl: $maxWidth
);
$font-family-base: 'Roboto', sans-serif;
$font-family-alt: 'Roboto Condensed', sans-serif;
$headings-font-family: 'Roboto', sans-serif;
$headings-font-weight: bold;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: ( 0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer, 4: ($spacer * 1.5),
	5: ($spacer * 1.75),
	6: ($spacer * 2),
	7: ($spacer * 2.25),
	8: ($spacer * 2.5),
	9: ($spacer * 3),
	10: ($spacer * 3.25),
	11: ($spacer * 3.5),
	12: ($spacer * 3.75),
	13: ($spacer * 4),
	14: ($spacer * 4.25),
	15: ($spacer * 4.5),
	16: ($spacer * 4.75),
	17: ($spacer * 5),
	18: ($spacer * 5.25),
	19: ($spacer * 5.25),
	20: ($spacer * 5.5),
	21: ($spacer * 5.75),
	22: ($spacer * 6),
	23: ($spacer * 6.25),
	24: ($spacer * 6.5),
	25: ($spacer * 6.75),
	26: ($spacer * 7),
	27: ($spacer * 7.25),
	28: ($spacer * 7.5),
	29: ($spacer * 7.75),
	30: ($spacer * 8))
	 !default;

// $input-btn-padding-y:null;
// $input-btn-padding-x:null;
$btn-border-radius:5px;
$btn-box-shadow:none;
$btn-focus-box-shadow:none;
$btn-active-box-shadow:none;
$btn-primary-bg: transparent;
$btn-primary-border-color: transparent;
$btn-secondary-color:$colorWhite;
$btn-secondary-bg: transparent;
$btn-secondary-border-color: transparent;
//style main site elements

// Main notification variables
$notifyMain-background: map-get(map-get($palettes, 'colorBrandBlue'), 'beyondlightest');
$notifyMain-color: inherit;
$notifyMain-heading-color: $colorBrandBlueDark;
$notifyMain-heading-size: 14px;
$notifyMain-toggle-color: $colorWhite;
$notifyMain-close-color: $colorWhite;
$notifyMain-close-size: 26px;

// Main header variables
$headerMain-navUtilMobile-border-color:$colorWhite;
$headerMain-util-background: $colorGrayLightest;;

$headerMain-navUtil-size: 14px;
$headerMain-navUtilLink-color: $colorWhite;
$headerMain-navUtilLink-active-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');


$headerMain-ctaBtn-background: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
$headerMain-ctaBtn-color:$colorWhite;
$headerMain-ctaBtn-size:13px;
$headerMain-ctaBtn-border-radius:25px;

$headerMain-search-background:$colorWhite;
$headerMain-search-border-radius:25px;
$headerMain-search-height:30px;

$headerMain-searchToggle-color:white;
$headerMain-searchToggle-active-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');
$headerMain-searchToggle-size:18px;

$headerMain-searchBox-size: 14px;

$headerMain-main-background: $colorWhite;

$headerMain-navMainLink-mobile-active-color:$colorWhite;
$headerMain-navMainLink-mobile-active-background:map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$headerMain-navMainLink-color: $colorBrandBlueDark;
$headerMain-navMainLink-active-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$headerMain-subContent-mobile-background:$colorWhite;
$headerMain-subContent-background:rgba(#ffffff, 1);

// $headerMain-subMainLink-color: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
// $headerMain-subMainLink-size: 18px;
// $headerMain-subMainLink-active-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$headerMain-subMainLink-mobile-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');
$headerMain-subMainLink-color: map-get(map-get($palettes, 'colorBrandBlue'), 'base');
$headerMain-subMainLink-size: 1.125rem;
$headerMain-subMainLink-active-color: map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$headerMain-subcollateralCta-color:$colorWhite;
$headerMain-subcollateralCta-hover-color:$colorWhite;
$headerMain-subcollateralCta-size:18px;
$headerMain-subcollateralCta-background:map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$notifyTray-toggle-color:map-get(map-get($palettes, 'colorBrandOrange'), 'base');
$notifyTray-toggle-hover-color:map-get(map-get($palettes, 'colorBrandOrange'), 'base');

// Main footer variables
$footerMain-background: map-get(map-get($palettes, 'colorBrandPurple'), 'base');
$footerMain-color: $colorWhite;
$footerMain-size: 14px;

$footerMain-main-border-color:map-get(map-get($palettes, 'colorBrandOrange'), 'base');

$footerMain-navLink-color:$colorWhite;
$footerMain-navUtilLink-hover-color:$colorWhite;
$footerMain-nav-size:14px;

$footerMain-topLink-background: $colorGrayLighter;
$footerMain-topLink-color:$colorGray;
$footerMain-topLink-hover-color:$colorBrandOrange;

$stdpadding: 2rem;
$stdpaddingMoblie: 1.5rem;

$smpadding: 1rem;
$smpaddingMoblie: 1rem;

//override default bootstrap font size
$font-size-h1: 2.875rem;
$font-size-h2: 1.875rem;
$font-size-h3: 1.4rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1.125rem;
$font-size-h6: 1.125rem;
//extra H styles
$font-size-h7: 1.25rem;
$font-size-h8: 0.875rem;

//mobile H Styles
$font-mobile-size-h1: 1.75rem;
$font-mobile-size-h2: 1.5625rem;
$font-mobile-size-h3: 1.4rem;
$font-mobile-size-h4: 1.25rem;
$font-mobile-size-h5: 1.125rem;
$font-mobile-size-h6: 1.125rem;
//extra H mobile styles
$font-mobile-size-h7: 1rem;
$font-mobile-size-h8: 0.625rem;

$grid-columns:      12;
$grid-gutter-width-base: 30px;


$grid-gutter-widths: (
  xs: 30px,
  sm: 30px,
  md: $grid-gutter-width-base, // 30px
  lg: $grid-gutter-width-base, // 30px
  xl: $grid-gutter-width-base  // 30px
)
