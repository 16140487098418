.quote {
    padding: 1rem 3rem;
    position: relative;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
        //padding-top: 0rem;
    }
    &:before, &:after {
        content: '';
        font-size: 5rem;
        color: $colorBrandPurple;
        position: absolute;
        font-family: Arial, Helvetica, sans-serif;
        width: 30px;
        height: 30px;
        
    }
    &:before {
        top: 0;
        left: 0;
        line-height: normal;
        background: url(__('/img/content-images/pull_quote_open.png'));
        background-size: 100%;
        background-repeat: no-repeat;
        top: 1rem;
    }
    &:after {
        bottom: 3rem;
        right: 0;
        line-height: normal;
        background: url(__('/img/content-images/pull_quote_closed.png'));
        background-size: 100%;
        background-repeat: no-repeat;
    }
}

.quote-title {
    font-weight: bold;
    margin-bottom: .25rem;
}

.quote-byline {
    text-align: right;
    color: $colorBrandPurple;
    margin-bottom: 0.5rem;
}

.quote--style1 {
    padding-left: 1rem;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
        padding-top: 0rem;
    }
    &:before, &:after {
        display: none;
    }
    .quote-title {
        position: relative;
        color: $white;
        font-weight: 400;
        font-size: 1.125rem;
        @include media-breakpoint-up(lg) {
            font-size: 1.40675rem;
        }
    }
    .quote-footnote {
        color: $white;
        font-size: 1rem;
    }
    .hangQuote {
        color: $colorBrandPurple;
        font-size: 2.2rem;
        line-height: 0;
        @include media-breakpoint-up(lg) {
            font-size: 2.875rem;
        }
    }
    .hangQuote--1 {
        position: absolute;
        left: -15px;
        top: 1.2rem;
        @include media-breakpoint-up(lg) {
            top: 1.45rem;
        }
    }
    .hangQuote--2 {
        position: relative;
        top: 0.75rem;
        @include media-breakpoint-up(lg) {
            top: 1rem;
        }
    }

}