.hcp-interstitial-overlay {
    background: rgba(0,0,0, 0.5);
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;

    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease;

    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 500;
    }
}

.hcp-interstitial-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 1170px;
    width: 100%;
    z-index: -1;

    opacity: 0;
    visibility: 0;
    transition: all 0.25s ease;

    &.active {
        height: auto;
        opacity: 1;
        width: 100%;
        visibility: visible;
        z-index: 510;
    }
}

.hcp-interstitial-content {
    background: #FFF;
    left: 5%;
    right: 5%;
    padding: $spacer*2 $spacer*2;
    position: relative;
    width: 90%;
    

    @include media-breakpoint-up(lg){
        left: 20%;
        right: 20%;
        width: 60%;
    }

    h2 {
        color: $colorBrandPurple;
        font-weight: 500;
        @include font-size(24px);

        @include media-breakpoint-up(lg){
            @include font-size(32px);
        }
    }

    p {
        @include font-primary();
    }

    &-buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: $spacer*2;
        justify-content: center;
        text-align: center;

        .button {
            flex: 0 0 100%;
            margin-bottom: $spacer*1;

            @include media-breakpoint-up(lg){
                flex: 0 0 35%;
                margin-right: 25px;
            }

        }

        .btn {
            flex: 0 0 100%;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            min-width: 220px;
            font-size: 1.25rem;
        }
    }

    &-close {
        background-color: $colorBrandPurple;
        border-radius: 50%;
        color: #FFF;
        margin-left:0rem;
        cursor:pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "\f00d"; 
            color: #FFF;
            display: block;
            font-family: 'FontAwesome', serif;
            position: relative;
        }

        &:hover, &:active {
            background: $colorBrandOrange;
        }
    }
}