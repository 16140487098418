// -----------------------------------------------------------------------------
// This file contains all styles related to global notify message.
// -----------------------------------------------------------------------------
.showToggle {
	color:palette(colorBrandOrange,base) !important;
	text-transform: lowercase;
	cursor: pointer;
	&:hover, &:active  {
		color:palette(colorBrandBlueDark,base) !important;
	}
}
.ellipse {
	font-weight: normal;
	color: $colorGray;
}
.notifyMain {
	background: #FFF;
	position:relative;
	transition: all .5s ease-in-out;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	overflow:hidden;
	// max-height: 515px;
	//transform: scale(1);
	&.is-closed {
		//padding:0;
		max-height: 0;
		//opacity:0;
		//transform: scale(1, 0);
		//transform-origin: top center;

	}
}
.notifyMain-inner {
	border-bottom: 2px solid $colorBrandBlueLightest;
	display:flex;
	max-width: $maxWidth;
	margin-right:auto;
	margin-left:auto;
	padding: 13px  $spacer*2;
	position: relative;

	@include media-breakpoint-up(lg){
		border: 2px solid $colorBrandBlueLightest;
		border-top: 0;
		//width: calc(1170px - 32px);
	}
}

.notifyMain-copy {
	@extend %noPhatBottom;
	transition: max-height .25s ease-in-out;
	line-height: 1.3;
	font-size: 0.875rem;
	font-family: $headings-font-family;

	p {
		display: block;
		margin-bottom: 15px;
		line-height: 1.25;
		font-size: 0.875rem;
		@include font-size(16px);

		&.preview {
			margin-bottom: 0;
		}
	}

	ul {
		line-height: 1.3;
		font-size: 0.875rem;
	    list-style: none;
	    color: $colorGray;

	    li:before {
	      content: "●";
	      color: $colorBrandOrange;
	      display: inline-block;
	      width: 1rem;
	      margin-left: -1rem;
	      margin-right: 0;
	      font-size: 0.75rem;
	      position: relative;
	      top: -2px;
	    }
	}

	a {
		color: $colorBrandPurple;
		font-weight: 600;
	}

	.hidden,
	.hide {
		line-height: 0;
		height: 0;
		margin: 0;
		opacity: 0;
		visibility: hidden;

		&.active {
			height: auto;
			opacity: 1;
			transition: all 0.5s ease;
			visibility: visible;
		}
	}
}

.notifyMain-heading {
	color: $colorBrandPurple;
	display: block;
	@include font-size(18px);
	@include font-primary;
	font-weight: 300;
	line-height: 1em;
	margin-bottom: 10px;
	font-weight: bold;
	@include media-breakpoint-up(lg){
		@include font-size(24px);
	}
}

.notifyMain-content {
	//@extend %noPhatBottom;
	//flex: 1 1 auto;
	width: 100%;
	flex-basis: 100%;
}

.notifyMain-close {
	background-color: $colorBrandPurple;
	border-radius: 50%;
	color: #FFF;
	margin-left:0rem;
	cursor:pointer;
	position: absolute;
	right: 8px;
	top: 8px;
	width: 32px;
	height: 32px;
	display: flex;
    align-items: center;
    justify-content: center;

	&::before {
		color: #FFF;
		display: block;
		position: relative;
		width: 13px;
		height: 13px;
		background-image: url(__("/img/template-images/icon-close-white.svg"));
		content: '';
	}

	&:hover, &:active {
		background: $colorBrandOrange;
	}
}

.hide-sm {
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.hide-lg {
	display: none;
}

.is-expanded {
	p.hide-sm, li.hide-sm {
		display:block;
	}
	span.hide-sm, span.hide-lg {
		display: inline;
	}
	.showToggle {
		display: none;
	}
}
